<template>
  <div :style="$parent.mobileBackground">
    <section class="section">

      <app-logo class="mt-5"/>

      <div class="card">
        <div class="card-content has-text-centered">
          <p class="has-text-weight-bold pb-2">{{$t('system.duplicateConnection')}}</p>
          <p class="is-size-7">{{$t('system.onlyOneSCreen')}}</p>
        </div>
        <footer class="card-footer p-4">
          <b-button
          type="is-primary"
          size="is-small"
          :label="$t('system.useThisOne')"
          @click="Reload()"
          expanded/>
        </footer>
      </div>     

    </section>
  </div>
</template>

<script scoped>
  export default {
    data() {
      return {};
    },
    methods: {
      Reload() {
        window.location.href="/";
      }
    }
  };
</script>
  
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  background-color: $system-screen-background;
}

.card {
    @include blurBox();
}
</style>